<script>
import NavbarComponent from "@/components/Navbar.vue";
import AboutMe from "@/components/AboutMe.vue";
import SkillsComponent from "@/components/SkillsComponent.vue";
import ProjectComponent from "@/components/ProjectComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import AOS from "aos";
import 'aos/dist/aos.css';
export default {
  name: "HomePage",
  components: {ContactComponent, ProjectComponent, SkillsComponent, AboutMe, NavbarComponent},
  mounted(){
    AOS.init();
  },
  data(){
    return{
      publicPath: process.env.BASE_URL
    }
  },
}
</script>

<template>
  <div class="container" data-aos="fade-down">
    <NavbarComponent />
    <div class="home-container" id="home">
      <h1>Hi, I'm <span>Julien Le Ber</span></h1>
      <p>I'm a student in <span>Cybersecurity</span></p>
      <a :href="`${publicPath}cv.pdf`" download="CV-Julien-Le_Ber"><p>Download CV</p></a>
    </div>
    <div class="scroll-container">
      <p>Scroll down</p>
      <p class="scroll">↓</p>
    </div>
    <about-me data-aos="fade-up"/>
    <skills-component data-aos="fade-up"></skills-component>
    <project-component data-aos="fade-up"></project-component>
    <contact-component data-aos="fade-up"></contact-component>
  </div>
</template>

<style scoped>
.home-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.scroll-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}
a p {
  align-items: center;
  width: 100%;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  text-decoration: none;
  transition: ease 0.5s;
  text-align: center;
  line-height: 10px;
}
a{
  width: 20%;
  height: 40px;
  color: white;
  border-radius:5px;
  background-color: #11D9C5;
  border: #011126 solid 2px;
  cursor: pointer;
  transition: ease 0.5s;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  text-decoration: none;
  align-content: center;
}
span{
  color: white;
}
a:hover{
  background-color: #011126;
  color: #11D9C5;
  transition: ease 0.5s;
  border: #11D9C5 solid 2px;
}
@media (width<600px ) {
  a{
    width: 40%;
  }
}
</style>