<script>
export default {
  name: "SkillCard",
  props:["skills"]
}
</script>

<template>
  <div class="skill-card">
    <div class="container" v-for="item in skills" :key="item">
      <div class="inner-container">
        <div class="essai">
          <h2>{{item.skill}}</h2>
        </div>
        <p>{{item.level}}</p>
        </div>
    </div>
  </div>
</template>

<style scoped>
.skill-card{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2,1fr);
  justify-content: center;
  align-items: center;
  color: #11D9C5;
  background: #011C40;
  border-radius: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  transition: ease 0.5s;
  border: #011C40 solid 2px;
}
.skill-card:hover{
  background: #011126;
  transition: ease 0.5s;
  border: #11D9C5 solid 2px;
}
.container{
  color: #11D9C5;
  margin-top: 10px;
}
.inner-container{
  display: grid;
  grid-template-rows: 40% 60%;
}
h2{
  color:white;
  text-align: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 18px;
}
.essai{
  border-bottom: #11D9C5 solid 2px;
  margin: 0 25%;
}
p{
  margin-top: 10px;
  opacity: 0.5;
  color: white;
}
@media (width<600px ) {
  h2{
    font-size: 16px;
  }
  .essai{
    border-bottom: #11D9C5 solid 2px;
    margin: 0 15%;
  }
}

</style>