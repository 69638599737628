<script>
export default {
  name: "AboutMeContent",
  props: ["title","content","imgPath","imgHoverPath"],
  data(){
    return{
      hovering: false
    }
  }
}
</script>

<template>
  <div class="content-container" @mouseenter="hovering=true" @mouseleave="hovering=false">
    <img :src="hovering ? imgHoverPath : imgPath">
    <h3>{{title}}</h3>
    <p>{{content}}</p>
  </div>
</template>

<style scoped>
.content-container{
  width: 100%;
  border-radius: 20px;
  background-color: #011C40;
  margin: 0 10px;
  border: #011126 solid 2px;
  transition: ease 0.5s;
  color:white;
}
h3{
  margin: 10px;
  color:#11D9C5;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-family: "Lato", sans-serif;
  transition: ease 0.5s;
}
img{
  width:51px;
  height: auto;
  transition: ease 0.5s;
}
.content-container:hover{
  background-color: #011126;
  color: #11D9C5;
  border: #11D9C5 solid 2px;
  transition: ease 0.5s;
}
@media (width<600px ) {
  .content-container{
    width: 100%;
    border-radius: 20px;
    background-color: #011C40;
    margin: 10px;
    border: #011126 solid 2px;
    transition: ease 0.5s;
    color:white;
  }
  h3{
    margin: 10px;
    color:#11D9C5;
    font-size: 10px;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    font-family: "Lato", sans-serif;
    transition: ease 0.5s;
  }
  img{
    width:51px;
    height: auto;
    transition: ease 0.5s;
  }
  .content-container:hover{
    background-color: #011126;
    color: #11D9C5;
    border: #11D9C5 solid 2px;
    transition: ease 0.5s;
  }
}
</style>