<script>
import SkillCard from "@/components/SkillCard.vue";

export default {
  name: "SkillsComponent",
  components: {SkillCard},
  data(){
    return{
      language : [{skill : "Python" , level : "Expert"},
                {skill : "Java" , level : "Expert"},
                {skill : "JavaScript" , level : "Intermediate"},
                {skill : "VueJs" , level : "Intermediate"},
                {skill : "C" , level : "Intermediate"},
                {skill : "Swift" , level : "Intermediate"},
      ],
      tools : [{skill : "Git" , level : "Expert"},
                {skill : "Nmap" , level : "Intermediate"},
                {skill : "Networking" , level : "Intermediate"},
                {skill : "Kali Linux" , level : "Intermediate"},
                {skill : "Metasploit" , level : "Basic"},
                {skill : "BurpSuite" , level : "Basic"},]
    }
  }
}
</script>

<template>
  <h2>My Skills</h2>
  <div class="skills-container">
    <div class="card-container">
      <h3>Language</h3>
      <skill-card :skills="language"></skill-card>
    </div>
    <div class="card-container">
      <h3>Tools</h3>
    <skill-card :skills="tools"></skill-card>
    </div>
  </div>
</template>

<style scoped>
.skills-container{
  display:grid;
  grid-template-columns: repeat(2, 40%);
  align-items: center;
  justify-content: center;
}
.card-container{
  margin: 10px 20px;
  display: grid;
  grid-template-rows: 20% 80%;
}
h2,h3{
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  margin-bottom: 40px;
}
@media (width<600px ) {
  .skills-container{
    display:grid;
    grid-template-columns: repeat(1, 100%);
    align-items: center;
    justify-content: center;
  }
  .card-container{
    margin: 10px 20px;
    display: grid;
    grid-template-rows: 20% 80%;
  }
  h2,h3 {
    color: #11D9C5;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    margin-bottom: 40px;
  }
}

</style>