<script>
export default {
  name: "ProjectCard",
  props: ["title", "description", "imgPath", "url"],
  data(){
    return{
      hovering: false
    }
  },
  methods: {
    goToGithub() {
      window.location = this.url
    }
  }
}
</script>

<template>
  <div class="project-card">
    <div class="image-wrapper">
    <img :src="imgPath" alt="project image">
    </div>
    <h2>{{title}}</h2>
    <p>{{description}}</p>
    <button @click="goToGithub">Github</button>
  </div>
</template>

<style scoped>
.project-card{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color:#011C40 ;
  border: #011126 solid 2px;
  transition: ease 0.5s;
}
.project-card:hover{
  background-color: #011126;
  color: #11D9C5;
  border: #11D9C5 solid 2px;
  transition: ease 0.5s;
}
.image-wrapper{
  width:90%;
  margin: 10px auto;
  border-radius: 20px;
}
img{
  width: 40%;
}
p{
  color: white;
  margin: 0 10px;
}
button{
  margin: 10px;
  width: 40%;
  height: 40px;
  color: white;
  border-radius:5px;
  background-color: #11D9C5;
  border: #011126 solid 2px;
  cursor: pointer;
  transition: ease 0.5s;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 20px;
}
button:hover{
  background-color: #011126;
  color: #11D9C5;
  transition: ease 0.5s;
  border: #11D9C5 solid 2px;
}

</style>