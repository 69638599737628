<script>
export default {
  name: "ContactCard",
  props: ["title","content","imgPath","imgHoverPath"],
  data(){
    return{
      hovering : false
    }
  }
}
</script>

<template>
  <div class="contact-card" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div class="image-wrapper">
      <img :src="hovering ? imgHoverPath : imgPath" alt="contact image">
    </div>
    <div class="wrapper">
      <h3>{{title}}</h3>
      <p>{{content}}</p>
    </div>
  </div>
</template>

<style scoped>
*{
  color: white;
}
.contact-card{
  width: 80%;
  height: 100%;
  margin: 0 auto;
  border-radius: 20px;
  background-color:#011C40 ;
  border: #011126 solid 2px;
  transition: ease 0.5s;
  display: grid;
  justify-content: center;
  align-items: center;
}
.contact-card:hover{
  background-color: #011126;
  color: #11D9C5;
  border: #11D9C5 solid 2px;
  transition: ease 0.5s;
}
.image-wrapper{
  width:100%;
  margin: 10px auto;
  border-radius: 20px;
  transition: ease 0.5s;
}
img{
  width: 40px;
  margin: 0 auto;
  transition: ease 0.5s;
}
h3{
  color: white;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .contact-card{
    width: 100%;
    height: 80%;
    border-radius: 20px;
    background-color: #011C40;
    border: #011126 solid 2px;
    transition: ease 0.5s;
    color:white;
  }
  h3{
    margin: 10px;
    color:#11D9C5;
  }
}
</style>