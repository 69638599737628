<script>
import ContactCard from "@/components/ContactCard.vue";

export default {
  name: "ContactComponent",
  components: {ContactCard}
}
</script>

<template>
  <div class="contact-container" id="contact">
    <h2>Contact Me</h2>
    <div class="contact-card-wrapper">
      <contact-card class="item" title="Email" content="julien.le-ber@efrei.net" :imgPath='require("@/assets/email.png")' :img-hover-path='require("@/assets/emailHover.png")'/>
      <contact-card class="item" title="Linkedin" content="Julien Le Ber" :imgPath="require('@/assets/linkedin.png')" :img-hover-path="require('@/assets/linkedinHover.png')"/>
      <contact-card class="item" title="Github" content="7ntys" :imgPath="require('@/assets/github.png')" :img-hover-path="require('@/assets/githubHover.png')"/>
    </div>
  </div>
</template>

<style scoped>
.contact-container{
  margin-top: 10%;
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 200px;
}
.contact-card-wrapper {
  display:grid;
  grid-template-columns: repeat(3, 25%);
  justify-content: center;
  margin: 10px 10px;
}
@media (max-width: 600px) {
  .contact-card-wrapper{
    display: grid;
    grid-template-columns: repeat(1, 100%);
    height: 90%;
    justify-content: center;
    align-items: center;
    color: #11D9C5;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    margin: 20px 20px;
  }
  .item{
    margin: 20px auto;
  }
}
</style>