<script>
import ProjectCard from "@/components/ProjectCard.vue";

export default {
  name: "ProjectComponent",
  components: {ProjectCard},
  data(){
    return{
      projects: [
        {
          title: "Beclean",
          description: "An ios app that let the user easily manage its airbnb cleaning.",
          imgPath: require("@/assets/beclean.png"),
          url: "https://github.com/7ntys/BeClean"
        },
        {
          title: "Alibi",
          description: "Ios and Web party game. Suddenly, you and your friend are accused of murder!",
          imgPath: require("@/assets/alibi.png"),
          url: 'https://github.com/7ntys/alibiWebsite'
        },
        {
          title: "My Portfolio",
          description: "The vue website you are currently on. Thats where i basically learned Vuejs.",
          imgPath: require("@/assets/logo.png"),
          url: "https://github.com/7ntys/portofolio"
        }
      ]
    }
  }
}
</script>

<template>
  <div class="project-container" id="projects">
    <h2>My Projects</h2>
    <div class="cards-container">
      <div class="wrapper" v-for="project in projects" :key="project.title">
      <project-card :title="project.title" :description="project.description" :img-path="project.imgPath" :url="project.url"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
h2{
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  margin-top: 80px;
}
.cards-container{
  display: grid;
  grid-template-columns: repeat(3, 25%);
  justify-content: center;
  align-items: center;
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  margin: 20px 20px;
}
.wrapper{
  margin: 5px;
}
@media (max-width: 600px) {
  .cards-container{
    display: grid;
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    align-items: center;
    color: #11D9C5;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    margin: 20px 20px;
  }
  .wrapper{
    margin: 5px;
  }
}
</style>