<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-link to="/about"></router-link>
    <router-link to="/projects"></router-link>
    <router-link to="/contact"></router-link>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  background-color: #011126;
}
body{
  background: #011126;
}
</style>
