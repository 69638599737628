<script>
export default {
  name: "NavbarComponent"
}
</script>

<template>
  <div class="nav-container">
    <a href="#home">Home</a>
    <a href="#about">About Me</a>
    <a a href="#projects">Projects</a>
    <a a href="#contact">Contact</a>
  </div>
</template>

<style scoped>
  .nav-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0;
    background: #011126;
    z-index: 2;
  }
  .nav-container a {
    margin: 10px;
    color: #11D9C5;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    cursor: pointer;
    font-size: 22px;
    text-underline: none;
    text-decoration: none;
  }
</style>