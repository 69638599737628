<script>
import AboutMeContent from "@/components/AboutMeContent.vue";

export default {
  name: "AboutMe",
  components: {AboutMeContent},
  data() {
    return {
      hovering: "container"
    };
  }
}
</script>

<template>
  <div class="about-container" id="about" data-aos="fade-up">
    <h2>Get to know me</h2>
    <div class="photo-container">
      <div class="container">
        <img src="../assets/Julien.png" alt="Julien Le Ber" />
      </div>
      <div class="photo-content">
        <div class="infos">
        <AboutMeContent title="Level" content="Student" :img-path="require('../assets/diploma.png')" :img-hover-path="require('../assets/diplomaHover.png')"/>
        <AboutMeContent title="Certification" content="2+" :img-path="require('../assets/Certif.png')" :img-hover-path="require('../assets/CertifHover.png')"/>
        <AboutMeContent title="Level" content="Student" :img-path="require('../assets/Folder.png')" :img-hover-path="require('../assets/FolderHover.png')"/>
        </div>
        <div class="text-content">
          <p>
            Currently pursuing a Master's degree in Cybersecurity at Efrei Paris, I am passionate about the ever-evolving challenges within this dynamic field. My insatiable curiosity and love for technical challenges make me a dynamic and adaptable individual, ready to tackle the most complex challenges in this ever-changing domain.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.photo-container{
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  margin: 15%;
}
img{
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: #011126 solid 2px;
  transition: ease 0.5s;
}
img:hover{
  border: #11D9C5 solid 2px;
  transition: ease 0.5s;
  opacity: 0.3;
}
.photo-content{
  width: 100%;
  justify-content: space-around;
}

.container {
  --border-width: 3px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  font-family: Lato, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  background: #011126;
  border-radius: 20px;

}
.infos{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
}
.text-content{
  margin-top: 10%;
  color: white;
  text-align: justify-all;
}
.about-container{
  display:grid;
  grid-template-rows: 10% 90%;
}
h2{
  margin-top:15%;
  padding: 0;
  color: #11D9C5;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 32px;
}
@media (width<600px ) {
  .container{
    width: 600px;
    height: 600px;
  }
  img{
    width: 100%;
    height: auto;
  }
  .photo-container{
    margin-top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-content{
    margin-top: 0;
    color: white;
    text-align: justify-all;
  }
  .about-container{
    margin-top:25%;
    display:grid;
    grid-template-rows: 10% 90%;
  }
  h2{
    padding: 0;
    color: #11D9C5;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 32px;
  }
  .infos{
    margin-top: 20%;
    width: 100%;
    align-items: center;
    display:grid;
    grid-template-columns: repeat(3, 30%);
  }
  .container{
    width: 100%;
    height: 100%;
  }
}
</style>