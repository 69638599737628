import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './components/HomePage.vue'
import About from './components/AboutMe.vue'
import Contact from '@/components/ContactComponent.vue'
import ProjectComponent from "@/components/ProjectComponent.vue";
import AOS from 'aos'
import 'aos/dist/aos.css'
const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    {path: '/projects', component: ProjectComponent},
    { path: '/contact', component: Contact },
    {path: '/:pathMatch(.*)*',name:'not-found',redirect:'/'}
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
AOS.init();
createApp(App).use(router).use(AOS).mount('#app')
